/* Profile.css */

/* Container styles */
.profile-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
}

/* Profile card styles */
.profile-card {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 375px;
    max-width: 100%;
    border: 2px solid #F7F8F9;
    background-color: #F7F8F9;
}

/* Profile header styles */
.profile-header {
    background-color: white;
    font-size: 1.125rem;
    font-weight: 600;
    color: #1D2226;
    padding: 1.25rem 1rem;
}

/* Profile data container styles */
.profile-data-container {
    display: flex;
    gap: 6px;
    padding: 0 1rem;
}

/* Profile picture container styles */
.profile-picture-container {
    position: relative;
    width: 5rem;
}

/* Profile picture styles */
.profile-picture {
    width: 100%;
}

/* Camera icon styles */
.camera-icon {
    width: 1.1rem;
    position: absolute;
    top: 65%;
    right: -3%;
}

/* Name and email styles */
.name-email {
    color: #1D2226;
}

/* Bio description styles */
.bio-description {
    padding: 0 1rem;
    font-size: 0.875rem;
    color: #1D2226;
    border-bottom: 1px dashed #718096;
    margin-bottom: 0;
}
